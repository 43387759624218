import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_id:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTA5NjI1NDgsImV4cCI6MTY1MzU1NDU0OCwianRpIjoiNzQ4NDVhMmJlNTBiYmI2MDJhYjA3ZjRkNzg5MDU5MmEifQ.MENYQbic6o3xI0vo3C_t9eh_XEXxXOcbWe6IsTqEzr8',
    p_id: '161',
    nickname: '',
  },
  mutations: {
    SET_ACCESS_ID(state, val) {
      state.access_id = val
    },
    SET_P_ID(state, val) {
      state.p_id = val
    },
    SET_NICKNAME(state, val) {
      state.nickname = val
    },
  },
  actions: {},
  modules: {},
})
