import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Feed',
    component: () => import('../views/adoption/feed.vue'),
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/adoption/detail.vue'),
  },
  {
    path: '/protocol',
    name: 'Protocol',
    component: () => import('../views/adoption/protocol.vue'),
  },
  {
    path: '/feedIndex',
    name: 'feedIndex',
    component: () => import('../views/feed/index.vue'),
  },
  {
    path: '/feedship',
    name: 'feedship',
    component: () => import('../views/feed/ship.vue'),
  },
  {
    path: '/more',
    name: 'more',
    component: () => import('../views/feed/more.vue'),
  },
  {
    path: '/proIndex',
    name: 'proIndex',
    component: () => import('../views/protocol/index.vue'),
  },
  {
    path: '/recomm',
    name: 'recomm',
    component: () => import('../views/protocol/recomm.vue'),
  },
  {
    path: '/playground',
    name: 'playground',
    component: () => import('../views/playground/playground.vue'),
  },
  {
    path: '/externallinks',
    name: 'externallinks',
    component: () => import('../views/externallinks/externallinks.vue'),
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
